<template>
  <div class="contract-approve">
    <div class="card">
      <img
        v-if="contract.validStatus == 5"
        src="/pass.svg"
        alt=""
        class="status"
      />
      <img
        v-if="contract.validStatus == 2"
        src="/no_pass.svg"
        alt=""
        class="status"
      />
      <van-cell-group title="合同信息">
        <van-cell
          center
          title-class="cell-title"
          value-class="cell-value"
          title="合同编号"
          :value="contract.contractCode"
        />
        <van-cell
          center
          value-class="cell-value"
          title-class="cell-title"
          title="学生姓名"
          :value="contract.studentName"
        />
        <van-cell
          center
          value-class="cell-value"
          title-class="cell-title"
          title="学生学号"
          :value="contract.stuNo"
        />
        <van-cell
          center
          value-class="cell-value"
          title-class="cell-title"
          title="是否一对多"
          :value="contract.isManyStr"
        />
        <van-cell
          center
          value-class="cell-value"
          title-class="cell-title"
          title="授课方式"
          :value="contract.onlineTypeStr"
        />
        <van-cell
          center
          value-class="cell-value"
          title-class="cell-title"
          title="交易日期"
          :value="contract.tradeDate"
        />
      </van-cell-group>
    </div>
    <div class="card">
      <van-cell-group title="课程信息" border>
        <div v-for="(item, index) in contract.courseInfo" :key="index">
          <van-cell
            title-class="cell-title-long"
            value-class="cell-value-price"
            :title="`${index + 1}. ${item.classTypeDesc} / ${
              item.courseName
            } / ${item.gradeName}`"
            :value="`单价：￥${item.price}`"
            :border="false"
          >
          </van-cell>
          <van-row class="row">
            <van-col span="6">购买课时</van-col>
            <van-col span="6">赠送课时</van-col>
            <van-col span="6">待排课时</van-col>
            <van-col span="6">授课老师</van-col>
          </van-row>
          <van-row class="row tbody">
            <van-col span="6">{{ item.formalClassHour }}</van-col>
            <van-col span="6">{{ item.giveClassHour }}</van-col>
            <van-col span="6">{{ item.surplusClassHour }}</van-col>
            <van-col span="6">{{ item.teacherName }}</van-col>
          </van-row>
        </div>
      </van-cell-group>
    </div>
    <div class="card">
      <van-cell-group title="其他信息" border>
        <van-row class="row row-1" justify="space-between" align="center">
          <van-col span="4">原价：</van-col>
          <van-col span="8" class="cell-value-price"
            >￥{{ contract.originalAmount }}</van-col
          >
          <van-col span="6">优惠金额：</van-col>
          <van-col span="6" class="cell-value-price"
            >￥{{ contract.discountAmount }}</van-col
          >
          <van-col span="12"
            >钱包抵扣金额：<span class="cell-value-price"
              >￥{{ contract.walletDiscountAmount }}</span
            ></van-col
          >
          <van-col span="12"
            >抹零金额：<span class="cell-value-price"
              >￥{{ contract.moZeroAmount }}</span
            ></van-col
          >
          <van-col span="12"
            >交易金额：<span class="cell-value-price"
              >￥{{ contract.tradeAmount }}</span
            ></van-col
          >
          <van-col span="24"
            >支付方式：<span class="blue-txt">{{
              contract.payTypeStr
            }}</span></van-col
          >
          <van-col span="24"
            >支付状态：<span class="blue-txt">{{
              contract.payStatusStr
            }}</span></van-col
          >
          <van-col span="24"
            >支付时间：<span class="blue-txt">{{
              contract.tradeDate
            }}</span></van-col
          >
          <van-col span="12"
            >是否完结：<span class="blue-txt">{{
              contract.isOverStr
            }}</span></van-col
          >
          <van-col span="12"
            >是否多价格：<span class="blue-txt">{{
              contract.isMultiPriceStr
            }}</span></van-col
          >
          <van-col span="24" v-if="contract.payType == 6"
            >分笔详情：<span
              style="margin-right: 10px"
              v-for="(o, i) in contract.amountList"
              :key="i"
              >{{ i + 1 }}.<span class="cell-value-price">￥{{ o }}</span></span
            ></van-col
          >
          <van-col span="12"
            >创建人：<span class="blue-txt">{{
              contract.createByName
            }}</span></van-col
          >
          <van-col span="12"
            >签单人：<span class="blue-txt">{{
              contract.signByName
            }}</span></van-col
          >
          <van-col span="24"
            >创建时间：<span class="blue-txt">{{
              contract.createTime
            }}</span></van-col
          >
          <van-col span="12"
            >合同类型：<span class="blue-txt">{{
              contract.contractTypeDesc
            }}</span></van-col
          >
          <van-col span="12"
            >申请类型：<span class="blue-txt">{{
              contract.applyTypeStr
            }}</span></van-col
          >
          <van-col span="12"
            >合同状态：<span class="cell-value-price">{{
              status[Number(contract.validStatus)]
            }}</span></van-col
          >
          <van-col span="24"
            >补充协议：<span class="blue-txt">{{
              contract.supplementaryAgreement
            }}</span></van-col
          >
          <van-col span="24"
            >备注：<span v-html="contract.remarksHtml"></span
          ></van-col>
        </van-row>
      </van-cell-group>
    </div>
    <div class="card" v-if="totalAmount > 0">
      <div
        class="add"
        @click="showUsers = true"
        v-if="contract.validStatus == 0"
      >
        <van-icon name="friends" size="18px" color="#3975C6" />
        <van-icon name="plus" size="14px" color="#3975C6" />
      </div>
      <van-cell-group
        title="合作打单"
        border
        v-if="contract.contractType != 3 && contract.contractType != 5"
      >
        <van-row class="row row-1 row-2" justify="space-between" align="center">
          <van-col span="24"
            >分成总金额：<span class="cell-value-price"
              >￥{{ totalAmount }}</span
            ></van-col
          >

          <van-col span="6">业绩归属人</van-col>
          <van-col span="6">分成比例</van-col>
          <van-col span="9">所得金额</van-col>
          <van-col span="3"></van-col>
        </van-row>
        <van-row
          class="row row-1 row-2"
          justify="space-between"
          align="center"
          v-for="(item, index) in users"
          :key="index"
        >
          <van-col span="6">{{ item.userName }}</van-col>
          <van-col span="6"
            ><span>{{ Number(item.profitRatio).toFixed(2) }}</span
            >&nbsp;<van-icon
              v-if="contract.validStatus == 0"
              name="edit"
              color="#3975C6"
              @click="handleShowEdit('分成比例', item)"
          /></van-col>
          <van-col span="9"
            ><span>￥{{ Number(item.profitAmount).toFixed(2) }}</span
            >&nbsp;<van-icon
              v-if="contract.validStatus == 0"
              name="edit"
              color="#3975C6"
              @click="handleShowEdit('所得金额', item)"
          /></van-col>
          <van-col
            span="3"
            style="line-height: 36px; text-align: center"
            v-if="contract.validStatus == 0"
          >
            <van-icon
              name="delete"
              size="large"
              color="#d50020"
              @click="users.splice(index, 1)"
            />
          </van-col>
        </van-row>
      </van-cell-group>
    </div>
    <div class="card">
      <van-field
        v-model="remark"
        type="textarea"
        label="审批备注"
        placeholder="请输入审批备注"
        required
        rows="3"
        autosize
        clearable
        :readonly="contract.validStatus != 0"
      />
    </div>
    <div class="opt" v-if="contract.validStatus == 0">
      <van-button
        type="info"
        :loading="loading"
        loading-text="审批中..."
        @click="debounceApprove(1)"
        >批准</van-button
      >
      <van-button
        type="danger"
        :loading="loading1"
        loading-text="审批中..."
        @click="debounceApprove(2)"
        >驳回</van-button
      >
    </div>
    <van-dialog
      v-model="showEdit"
      :title="current.userName + '的' + title"
      show-cancel-button
      lockScroll
      @confirm="handleConfirm"
    >
      <van-stepper
        v-if="title == '分成比例'"
        :min="0"
        :max="maxRate"
        v-model="current.profitRatio"
        :step="0.01"
        :decimal-length="2"
        class="step"
        button-size="28px"
        input-width="60px"
      />
      <span v-if="title == '分成比例'" class="cell-value-price"
        >所得金额 ￥{{
          Number(totalAmount * current.profitRatio).toFixed(2)
        }}</span
      >
      <van-stepper
        v-else
        :min="0"
        v-model="current.profitAmount"
        :step="1"
        :decimal-length="2"
        :max="maxPrice"
        class="step"
        button-size="32px"
        input-width="80px"
      />
      <span v-if="title == '所得金额'" class="cell-value-price"
        >分成比例&nbsp;{{
          Math.floor((current.profitAmount / totalAmount) * 100) / 100
        }}</span
      >
    </van-dialog>
    <van-popup
      v-model="showUsers"
      position="bottom"
      round
      :style="{ height: '50%' }"
      lock-scroll
      closeable
      @close="
        checked = [];
        members = allMembers;
      "
      class="members"
    >
      <div class="pop-title">添加合作打单成员</div>
      <van-search
        v-model="searchKey"
        placeholder="请输入搜索关键词"
        @input="debounceSearch"
        @search="debounceSearch"
        @clear="members = allMembers"
      />
      <van-checkbox-group v-model="checked">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in members"
            clickable
            :key="item.id"
            :title="item.userName"
            @click="handleToggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item" ref="checkboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
      <van-empty v-if="!members.length" image="search" description="查无此人" />
      <div class="bottom-bar">
        <div class="has-check" v-if="checked.length">
          已勾选：{{
            checked
              .map((e) => {
                return e.userName;
              })
              .join("、")
          }}
        </div>
        <div class="bottom-btns">
          <van-button plain color="#3975C6" @click="checked = []"
            ><span style="color: #3975c6">重置</span></van-button
          >
          <van-button color="#3975C6" @click="debounceAdd"
            ><span style="color: #ffffff">确认添加</span></van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
export default {
  name: "ContractApprove",
  data() {
    return {
      status: [
        "待审核",
        "已生效",
        "审批驳回",
        "财务待审批",
        "财务审批驳回",
        "审批通过",
        "已失效",
      ],
      remark: "",
      course: [],
      users: [],
      totalAmount: 0,
      showEdit: false,
      title: "分成比例",
      current: {},
      maxRate: 1,
      maxPrice: 1000,
      showUsers: false,
      searchKey: "",
      allMembers: [],
      members: [],
      checked: [],
      loading: false,
      loading1: false,
      //获取的详情数据
      contract: {},
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserId;
    },
  },
  created() {
    if (!this.userId && !sessionStorage.getItem("userId")) {
      this.$toast.fail({ icon: "close", message: "登录失败", duration: 5000 });
      return false;
    }
    if (!this.$route.query.id) {
      this.$toast.fail({ icon: "close", message: "参数缺失", duration: 5000 });
      return false;
    }
    this.debounceSearch = _.debounce(this.handleSearch, 500);
    this.debounceApprove = _.debounce(this.handleApprove, 500);
    this.debounceAdd = _.debounce(this.handleConfirmAdd, 500);
    this.handleGetContractDetail();
    this.handleGetCooperate();
    this.handleGetUsers();
  },
  methods: {
    handleGetContractDetail() {
      const toast = this.$toast.loading("加载中");
      this.$http
        .get("/front/qywx/contract/contractDetail", {
          id: this.$route.query.id,
          userId: this.userId,
        })
        .then((res) => {
          if (res.code == 1) {
            this.contract = { ...res.data };
            this.totalAmount = res.data.tradeAmount;
            this.maxPrice = res.data.tradeAmount;
            this.remark = res.data.approvalRemark;
            toast.clear();
          } else {
            toast.clear();
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          toast.clear();
          console.log(err);
        });
    },
    handleGetCooperate() {
      this.$http
        .get("/front/qywx/contract/listByContractId", {
          contractId: this.$route.query.id,
          userId: this.userId,
        })
        .then((res) => {
          if (res.code == 1) {
            this.users = [...res.data];
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleGetUsers() {
      this.$http
        .get("/front/userList", {
          roleConfKey: "contract.salesman.role.codes",
          isCheckRole: false,
        })
        .then((res) => {
          if (res.code == 1) {
            this.allMembers = res.data;
            this.members = res.data;
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleApprove(type) {
      const { users, contract } = this;
      const rateSum = users.reduce((prev, e) => {
        return Number(prev) + Number(e.profitRatio);
      }, 0);
      const priceSum = users.reduce((prev, e) => {
        return Number(prev) + Number(e.profitAmount);
      }, 0);
      if (!this.remark) {
        this.$toast("请输入审批备注");
      } else if (
        contract.contractType != 3 &&
        contract.contractType != 5 &&
        type != 2
      ) {
        if (
          Number(rateSum) !== 1 ||
          Number(priceSum) !== Number(this.totalAmount)
        ) {
          this.$toast.fail({
            message: "请正确填写分成比例及所得金额",
            duration: 3000,
          });
        }else{
          this.$dialog
          .confirm({
            title: "提示",
            message: type == 1 ? "确认批准申请？" : "确认驳回申请？",
          })
          .then(() => {
            // on confirm
            this.handleApproveAjax(type);
          });
        }
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: type == 1 ? "确认批准申请？" : "确认驳回申请？",
          })
          .then(() => {
            // on confirm
            this.handleApproveAjax(type);
          });
      }
    },
    handleApproveAjax(type) {
      const url =
        type == 1
          ? "/front/qywx/contract/supervisorApprovalPass"
          : "/front/qywx/contract/supervisorApprovalReject";
      const { applyId } = this.contract;
      const { users, remark } = this;
      const auditStatus = type == 1 ? "1" : "-1";
      type == 1 ? (this.loading = true) : (this.loading1 = true);
      const loadingToast = this.$toast.loading({
        message: "审批中",
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      this.$http
        .post(url, {
          userId: this.userId,
          applyId,
          auditStatus,
          profitList: users,
          reason: remark,
        })
        .then((res) => {
          if (res.code == 1) {
            loadingToast.clear();
            this.$toast.success({
              message: `审批成功，已${type == 1 ? "批准" : "驳回"}`,
              duration: 2500,
            });
            setTimeout(() => {
              location.reload();
            }, 2500);
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          loadingToast.clear();
          console.log(err);
        });
    },
    handleConfirmAdd() {
      if (!this.checked.length) {
        this.$toast.fail("未勾选成员");
      } else {
        const checked = this.checked.map((e) => {
          return {
            userId: e.id,
            userName: e.userName,
            profitRatio: 0,
            profitAmount: 0,
          };
        });
        this.users = [...this.users, ...checked];
        this.showUsers = false;
      }
    },
    handleToggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    handleSearch(search_key) {
      const { allMembers } = this;
      this.members = allMembers.filter((e) => {
        return e.userName.includes(search_key);
      });
    },
    handleRateOrPrice(cb) {
      const users = JSON.parse(JSON.stringify(this.users));
      this.title == "分成比例"
        ? (this.maxRate =
            1 -
            users.reduce((prev, e) => {
              return Number(prev) + Number(e.profitRatio);
            }, 0) +
            Number(this.current.profitRatio))
        : (this.maxPrice =
            this.totalAmount -
            users.reduce((prev, e) => {
              return Number(prev) + Number(e.profitAmount);
            }, 0) +
            Number(this.current.profitAmount));
      cb();
    },
    handleShowEdit(title, item) {
      this.title = title;
      this.current = Object.assign({}, item);
      this.handleRateOrPrice(() => {
        this.showEdit = true;
      });
    },
    handleConfirm() {
      const users = JSON.parse(JSON.stringify(this.users));
      const { current, title, totalAmount } = this;
      users.forEach((v) => {
        if (v.userId == current.userId) {
          if (title == "分成比例") {
            v.profitRatio = current.profitRatio;
            v.profitAmount = (totalAmount * current.profitRatio).toFixed(2);
          } else {
            v.profitAmount = current.profitAmount;
            v.profitRatio =
              Math.floor((current.profitAmount / totalAmount) * 100) / 100;
          }
        }
      });
      this.users = users;
    },
  },
};
</script>
<style lang="less" scoped>
.contract-approve {
  padding-bottom: 100px;
  .opt {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .van-button {
      width: 49%;
    }
  }
  .card {
    position: relative;
    .status {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      width: 100px;
      opacity: 0.8;
    }
    .add {
      position: absolute;
      top: 15px;
      right: 20px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .van-cell-group__title {
      font-size: 16px;
      color: #444342;
      font-weight: bold;
    }
    .cell-title {
      flex: none;
      font-size: 14px;
    }
    .cell-title-long {
      flex: 2;
      font-size: 14px;
      white-space: pre-wrap;
      word-break: break-all;
      color: #09205f;
      // color: #3975C6;
    }
    .cell-label {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }

    .cell-value-price {
      color: #d50020;
      font-size: 14px;
    }
    .row {
      padding: 0 16px;
      .van-col {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        white-space: pre-wrap;
        word-break: break-all;
        color: #6a6a6a;
        &:not(:last-of-type) {
          border-right: 1px solid #eee;
        }
      }
    }
    .row-1 {
      .van-col {
        border: none !important;
        line-height: 30px;
        margin: 0;
        text-align: left;
        .blue-txt {
          color: #3975c6;
        }
        .green-txt {
          color: green;
        }
      }
      .cell-value-price {
        color: #d50020;
        font-size: 14px;
        text-align: left;
      }
    }
    .tbody {
      padding-bottom: 10px;
      border-bottom: 1px solid #eeeeee;
      .van-col {
        color: #444342;
      }
    }
  }
  ::v-deep.van-dialog__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .step {
      display: block;
      margin: 10px 0;
    }
    .cell-value-price {
      color: rgb(213, 0, 32);
      font-size: 14px;
      text-align: center;
      margin-bottom: 1em;
    }
  }
  .pop-title {
    text-align: center;
    padding-top: 10px;
    line-height: 40px;
  }
  .members {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .bottom-bar {
      width: 100%;
      position: fixed;
      bottom: 0;
      background: #ffffff;
      .has-check {
        width: 100%;
        overflow-x: auto;
        padding: 6px;
        font-size: 12px;
        color: rgba(213, 0, 32, 0.6);
      }
      .bottom-btns {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .van-button {
          flex: 1;
          width: 0;
          border-radius: 0;
        }
      }
    }
    .van-checkbox-group {
      flex: 1;
      height: 0;
      overflow-y: auto;
      padding-bottom: 80px;
    }
  }
}
</style>
